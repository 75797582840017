import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  ButtonWrapper,
  HeaderMain,
  HeaderWrapper,
  TitleWapper,
  MenuToggle,
  NavBarWrap,
  NavLinkStyled,
  NavLink2,
  StyledWalletIcon,
  HeaderMobile,
  NavLinkA,
  MobileLogo,
} from './Styled'

import { FiMenu } from 'react-icons/fi'

import ParasdisoLogo from 'assets/images/paradiso-logo.svg'

function HeaderSecond(): JSX.Element {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  return (
    <HeaderMain>
      <HeaderWrapper>
        <TitleWapper onClick={() => navigate('/')}>
          <img src={ParasdisoLogo} />
          <p style={{ fontSize: '1.25rem', fontFamily: 'Montserrat', color: '#fff', fontWeight: '500' }}>PARADISO</p>
        </TitleWapper>
        <NavBarWrap className="d-none d-lg-flex">
          <ul className="main-menu list-unstyled">
            <li>
              <NavLinkA href="/">Home</NavLinkA>
            </li>
            <li>
              <NavLinkA href="#products">Products</NavLinkA>
            </li>
            <li>
              <NavLinkA href="#partners">Partners</NavLinkA>
            </li>
            <li>
              <NavLinkA href="#blog">Blog</NavLinkA>
            </li>
          </ul>
        </NavBarWrap>
        <ButtonWrapper>
          <button
            style={{ padding: "0.5rem 1.75rem", backgroundColor: 'transparent', color: '#fff', border: '1px solid #fff', borderRadius: '1rem' }}
            onClick={() => window.open("https://app.paradiso.io/", "_blank")}
          >
            Launch App
          </button>
        </ButtonWrapper>
      </HeaderWrapper>
      <HeaderMobile>
        <div className='d-flex align-items-center gap-3'>
          <img src={ParasdisoLogo} width={35}/>
          <p style={{ fontSize: '1.5rem', fontFamily: 'Montserrat', color: '#fff' }}>PARADISO</p>
        </div>
        <MenuToggle onClick={handleMobileMenuOpen}>
          <FiMenu size={24} color='#fff'/>
        </MenuToggle>
        {/* <MobileLogo src={LogoLight} /> */}
        <Offcanvas show={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)}>
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
            <ul className="main-menu list-unstyled">
              <li>
                <NavLinkA href="/">Home</NavLinkA>
              </li>
              <li>
                <NavLinkA href="#products">Products</NavLinkA>
              </li>
              <li>
                <NavLinkA href="#partners">Partners</NavLinkA>
              </li>
              <li>
                <NavLinkA href="#blog">Blog</NavLinkA>
              </li>
              <li>
                <NavLinkA href="https://app.paradiso.io/" target='_blank'>Lanch App</NavLinkA>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </HeaderMobile>
    </HeaderMain>
  )
}

export default HeaderSecond
