import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderSecond from "./components/HeaderSecond";
import Footer from "components/Footer";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/fonts/stylesheet.css";
import "assets/scss/style.scss";

import HomePage from "pages/Home";

import { ThemeProvider } from "styled-components/macro";
import { lightTheme, GlobalStyles } from "./themes";

// eslint-disable-next-line
function App() {
  return (
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <Router>
          <HeaderSecond />
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
  );
}

export default App;
