import styled from 'styled-components/macro'

export const FirstSection = styled.div`
  background: linear-gradient(to right, #00fffd , #13599D);
  padding: 5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: relative;
  margin-top: 3rem;

  @media (min-width: 992px) {
    padding: 5rem 8rem;
    padding-bottom: 2.8rem;
    margin-top: 0rem;
  }

  @media (min-width: 1200px) {
    padding: 5rem 12rem;
    padding-bottom: 2.8rem;
    margin-top: 0rem;
  }

  @media (min-width: 1600px) {
    padding: 5rem 23rem
    padding-bottom: 10rem;
    margin-top: 0rem;
  }

`
export const ProductSection = styled.div`
  padding: 5rem 1.5rem;

  @media (min-width: 992px) {
    padding: 5rem 8rem;
  }

  @media (min-width: 1200px) {
    padding: 5rem 12rem;
  }

  @media (min-width: 1600px) {
    padding: 5rem 23rem;
  }
`
export const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: fit-content;
    border: 1px solid #13599d;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    color: #13599d;
    background-color: #fff;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const ImageAbsolute = styled.img`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`
export const SupportedChains = styled.div`
  padding: 5rem 1.5rem;

  @media (min-width: 992px) {
    padding: 5rem 8rem;
  }

  @media (min-width: 1200px) {
    padding: 5rem 12rem;
  }

  @media (min-width: 1600px) {
    padding: 5rem 23rem;
  }
`
export const HowToUse = styled.div`
  padding: 7rem 1.5rem;

  @media (min-width: 992px) {
    padding: 7rem 8rem;
  }

  @media (min-width: 1200px) {
    padding: 7rem 12rem;
  }

  @media (min-width: 1600px) {
    padding: 7rem 23rem;
  }
`
export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
`
export const StepItem = styled.div`
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 50%;
  background-color: #13599d;
  color: #fff;
`
export const TextStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
`
export const Line = styled.div`
  width: 1px;
  height: 5rem;
  background-color: #13599d;

  @media (min-width: 768px) {
    width: 10rem;
    height: 1px;
    background-color: #13599d;
  }
`
export const InvestorAndPartner = styled.div`
  padding: 5rem 1.5rem;
  background: linear-gradient(to right, #00fffd , #13599D);

  @media (min-width: 1200px) {
    padding: 5rem 12rem;
  }

  @media (min-width: 1600px) {
    padding: 5rem 25rem;
  }
`
export const Blog = styled.div`
  padding: 5rem 1.5rem;

  @media (min-width: 992px) {
    padding: 5rem 8rem;
  }

  @media (min-width: 1200px) {
    padding: 5rem 8rem;
  }

  @media (min-width: 1600px) {
    padding: 5rem 23rem;
  }
`
export const SectionTitle = styled.p`
  font-family: Montserrat;
  color: #13599d;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
`

