import { useRef } from 'react'
import Slider from "react-slick";
import ArcadiaLogo from 'assets/images/arcadia.svg'
import BlockSync from 'assets/images/blocksync.svg'
import Thorchain from 'assets/images/thorchain.svg'
import Firo from 'assets/images/firo.svg'
import Kryptos from 'assets/images/kryptos.svg'
import Bip32 from 'assets/images/bip32.svg'
import Ventures from 'assets/images/venture.svg'
import Shima from 'assets/images/shima.svg'
import Genblock from 'assets/images/genblock.svg'
import BscStation from 'assets/images/bsc-station.svg'
import Sentor from 'assets/images/sentor.svg'
import Mozaik from 'assets/images/mozaik.svg'
import Kieran from 'assets/images/kieran.svg'
import Ld from 'assets/images/ld-capital.svg'
import PolkaWar from 'assets/images/polkawar.svg'
import Its from 'assets/images/its-bloackchain.svg'
import Sandbar from 'assets/images/sandbar.svg'
import Dutch from 'assets/images/dutch.svg'
import BtcEx from 'assets/images/btc-ex.png'
import CheerLand from 'assets/images/cheerland.png'
import ChinaPolka from 'assets/images/chianpolka.png'
import Contenttos from 'assets/images/contentos.png'
import Jrr from 'assets/images/jrr.png'
import Dreamboat from 'assets/images/dreamboat.png'
import Oig from 'assets/images/oig.png'
import Rio from 'assets/images/rio.png'
import Shadow from 'assets/images/shadow.png'
import Talken from 'assets/images/talken.png'
import Trustfi from 'assets/images/trust-fi.png'
import CherrySwap from 'assets/images/cherry-swap.png'
import Aurora from 'assets/images/aurora.png'
import Bizverse from 'assets/images/bizverse.png'
import CasperArmy from 'assets/images/casper-army.png'
import CasperDash from 'assets/images/casper-dash.png'
import EMoney from 'assets/images/e-money.png'
import Fuse from 'assets/images/fuse.png'
import Gate from 'assets/images/gate-chain.png'
import Kardia from 'assets/images/kardia.png'
import OKc from 'assets/images/okc-chain.png'
import Trisolaris from 'assets/images/trisolaris.png'
import Piggy from 'assets/images/we-piggy.png'

const listInvestorAndPartner = [
    { image: ArcadiaLogo, url: 'https://www.arcadiamgroup.com/' },
    { image: BlockSync, url: "https://www.blocksync.com/" },
    { image: Thorchain, url: "https://thorchain.org/" },
    { image: Firo, url: "https://firo.org/" },
    { image: Kryptos, url: "https://kryptos.fund/" },
    { image: Bip32, url: "http://bip32.vc/" },
    { image: Ventures, url: "https://hc.ventures/" },
    { image: Shima, url: "https://shima.capital/" },
    { image: Genblock, url: "https://genblock.capital/" },
    { image: BscStation, url: "https://www.bscs.finance/" },
    { image: Sentor, url: "https://sentorinvestments.com.au/" },
    { image: Mozaik, url: "https://www.mozaikcapital.com/" },
    { image: Kieran, url: "https://illuvium.io/" },
    { image: Ld, url: "https://ldcap.com/" },
    { image: PolkaWar, url: "https://polkawar.com/" },
    { image: Its, url: "https://itsblockchain.com/" },
    { image: Sandbar, url: "https://www.sandbarinvestments.com.au/" },
    { image: Dutch, url: "https://dutchcryptoinvestors.com/" },
    { image: Jrr, url: "http://jrr.group" },
    { image: Oig, url: "https://https://oiginvest.com/#home" },
    { image: BtcEx, url: "https://www.bitcoin.com/bitcoin-exchange-directory/" },
    { image: Contenttos, url: "https://www.contentos.io/" },
    { image: Talken, url: "https://talken.io/" },
    { image: Dreamboat, url: "http://dreamboatcapital.com/" },
    { image: ChinaPolka, url: "http://chinapolka.com/" },
    { image: CheerLand, url: "https://cheersland.org/" },
    { image: Rio, url: "https://riochain.io/" },
    { image: Shadow, url: "https://www.shadows.link/#/" },
    { image: Trustfi, url: "https://trustfi.org/" },
    { image: CherrySwap, url: "https://www.cherryswap.net/#/swap" },
    { image: Aurora, url: "https://aurora.dev/" },
    { image: Bizverse, url: "https://bizverse.io/?lang=vi" },
    { image: CasperArmy, url: "https://casperarmy.io/" },
    { image: CasperDash, url: "https://casperdash.io/" },
    { image: EMoney, url: "https://e-money.com/" },
    { image: Fuse, url: "https://www.fuse.io/" },
    { image: Gate, url: "https://gatechain.io/" },
    { image: Kardia, url: "https://kardiachain.io/" },
    { image: OKc, url: "https://www.okx.com/" },
    { image: Trisolaris, url: "https://www.trisolaris.io/#/" },
    { image: Piggy, url: "https://wepiggy.com/" },
]

export default function Investor() {
    let sliderRef = useRef(null);

    const settings = {
        centerMode: false,
        arrows: false,
        dots: true,
        swipeToSlide: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        slidesToShow: 1,
        speed: 500,
        rows: 3,
        slidesPerRow: 6,
        responsive: [
            {
                breakpoint: 1052,
                settings: {
                    slidesToShow: 1,
                    rows: 3,
                    slidesPerRow: 5,
                }
            },
            {
                breakpoint: 938,
                settings: {
                    slidesToShow: 1,
                    rows: 3,
                    slidesPerRow: 4,
                }
            },
            {
                breakpoint: 592,
                settings: {
                    slidesToShow: 1,
                    rows: 3,
                    slidesPerRow: 3,
                }
            },
        ]
    };
    return (
        <>
            <Slider ref={slider => (sliderRef = slider)} {...settings}>
                {listInvestorAndPartner.map((item, index) => (
                    <div style={{ width: '10px' }} onClick={() => window.open(item.url, "_blank")}>
                        <img src={item.image} style={{ width: '100%' }} />
                    </div>
                ))}
            </Slider>
        </>
    )
}